import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { TimetableEvent, blockModal } from "../../types/types";
import Close from "../Icons/Close";
import Edit from "../Icons/Edit";
import BlockForm from "./BlockForm";
import Swap from "../Icons/Swap";
import Delete from "../Icons/Delete";
import { useGetUser } from "../../hooks/queries/useLoginQuery";
import { mapFormDataToTimetableEvent, useDeleteEvent, useEditEvent } from "../../hooks/queries/EventQuery";

const BlockModal: React.FC<blockModal> = ({ isOpen, onClose, initialData }) => {
  const [formData, setFormData] = useState(initialData);
  const { data: userData } = useGetUser();
  const { mutate: editMutate } = useEditEvent();
  const { mutate: deleteMutate } = useDeleteEvent();
  

  useEffect(() => {
    setFormData(initialData || {});
  }, [initialData]);

  const [isEditFormOpen, setEditFormOpen] = useState(false);

  const openEditForm = () => {
    setEditFormOpen(true);
  };

  const closeEditForm = () => {
    setEditFormOpen(false);
  };

  const handleEditFormSubmit = async (formData: TimetableEvent) => {
    try {
      if (userData) {
        const timetableEvent = mapFormDataToTimetableEvent(formData);
        console.log(initialData);
        console.log(formData);
        editMutate({ formData: initialData, timetableEvent: timetableEvent });
        onClose();
        console.log("Form submitted with data:", formData);
      } else {
        console.error("User data not available");
      }
    } catch (error) {
      console.log(error);
    }
    closeEditForm();
  };

  const handleDelete = async () => {
    try {
      if (userData) {
        console.log(formData);
        deleteMutate({ formData });
        onClose();
        console.log("Handle delete for", formData.course.name);
      } else {
        console.error("User data not available");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getModalColor = (type: string) => {
    switch (type) {
      case "lecture":
        return "bg-lecture";
      case "laboratory":
        return "bg-laboratory";
      case "exercise":
        return "bg-exercise";
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-full max-w-md p-4 overflow-x-hidden overflow-y-auto bg-white rounded-lg shadow-lg"
      contentLabel="Block Modal"
      style={{
        overlay: {
          backgroundColor: "rgba(0, 0, 0, 0.5)",
        },
      }}
    >
      <div id="authentication-modal">
        <div className="relative w-full">
          <div className={`relative rounded-t-lg ${getModalColor(formData.type)} text-white p-4`}>
            {/* Header Section */}
            <div className="flex items-center justify-between">
              <h2 className="text-xl font-bold">{formData.course.name}</h2>
              <div className="flex space-x-2">
                {/* Swap Button */}
                <button
                  type="button"
                  className="text-blue-500 hover:bg-gray-100 hover:text-blue-700 rounded-lg text-sm w-8 h-8 inline-flex justify-center items-center focus:outline-none focus:ring focus:border-blue-300"
                  onClick={onClose}
                >
                  <Swap />
                  <span className="sr-only">Close</span>
                </button>
                {/* Edit Button */}
                <button
                  type="button"
                  className="text-blue-500 hover:bg-gray-100 hover:text-blue-700 rounded-lg text-sm w-8 h-8 inline-flex justify-center items-center focus:outline-none focus:ring focus:border-blue-300"
                  onClick={openEditForm}
                >
                  <Edit />
                  <span className="sr-only">Edit</span>
                </button>
                {/* Delete Button */}
                <button
                  type="button"
                  className="text-blue-500 hover:bg-gray-100 hover:text-blue-700 rounded-lg text-sm w-8 h-8 inline-flex justify-center items-center focus:outline-none focus:ring focus:border-blue-300"
                  onClick={handleDelete}
                >
                  <Delete />
                  <span className="sr-only">Edit</span>
                </button>
                {/* Close Button */}
                <button
                  type="button"
                  className="text-blue-500 hover:bg-gray-100 hover:text-blue-700 rounded-lg text-sm w-8 h-8 inline-flex justify-center items-center focus:outline-none focus:ring focus:border-blue-300"
                  onClick={onClose}
                >
                  <Close />
                  <span className="sr-only">Close</span>
                </button>
              </div>
            </div>
          </div>

          <div className="rounded-b-lg bg-white">
            <p className="text-xl font-bold">{formData.teachers[0].fullName}</p>
            <p className="text-xl font-bold">{formData.room.name}</p>
          </div>
        </div>
      </div>
      <BlockForm
        isOpen={isEditFormOpen}
        onClose={closeEditForm}
        onSubmit={handleEditFormSubmit}
        initialData={formData}
      />
    </Modal>
  );
};

export default BlockModal;
