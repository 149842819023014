import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { TimetableEvent, modalForm, TimetableEventForm, CourseGUI } from "../../types/types";
import Close from "../Icons/Close";
import "./FormStyle.css";
import { useGetAllCourses } from "../../hooks/queries/CourseQuery";
import { Combobox, ComboboxInput, ComboboxOption, ComboboxOptions } from "@headlessui/react";
import { v1 as uuid } from "uuid";

const BlockForm: React.FC<modalForm> = ({ isOpen, onClose, onSubmit, initialData }) => {
  const [selectedOption, setSelectedOption] = useState("Class");
  const [formData, setFormData] = useState<TimetableEventForm>({
    subject: "",
    professor: "",
    room: "",
    day: 0,
    startTime: 0,
    duration: 0,
    type: "",
    color: "",
    description: "",
  });

  const [options, setOptions] = useState<CourseGUI[]>([]);
  const [selectedCourse, setSelectedCourse] = useState<CourseGUI | null>({
    code: "",
    shortcut: "",
    name: formData.subject,
    type: "",
    facultyName: "",
    studyYear: 0,
    studyField: "",
  });

  useEffect(() => {
    if (isOpen && initialData) {
      setFormData({
        subject: initialData.course.name,
        professor: initialData.teachers[0].fullName,
        room: initialData.room.name,
        day: initialData.day,
        startTime: initialData.startTime,
        duration: initialData.duration,
        type: initialData.type,
        color: "#5ebed7",
        description: "",
      });
    }
  }, [isOpen, initialData]);

  const mapFormDataToTimetableEvent = (formData: TimetableEventForm): TimetableEvent => {
    return {
      course: {
        code: "",
        shortcut: "",
        name: formData.subject,
        type: "",
      },
      room: {
        name: formData.room,
        type: "",
      },
      teachers: [
        {
          fullName: formData.professor,
          roles: [{ roleType: "" }],
          email: "",
        },
      ],
      type: formData.type,
      day: formData.day,
      startTime: formData.startTime,
      duration: formData.duration,
    };
  };

  const handleFormSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSubmit(mapFormDataToTimetableEvent(formData));
    closeModal();
  };

  const closeModal = () => {
    onClose();
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setFormData((prevData: TimetableEventForm) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const allCourses = useGetAllCourses();
  const handleInputChangeComboBox = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { value } = e.target;
    if (value) {
      allCourses.mutate(value, {
        onSuccess: (data) => {
          const uniqueData = data.reduce((acc: typeof data, item) => {
            if (!acc.some((existingItem) => existingItem.name === item.name)) {
              acc.push({
                ...item,
                name: item.name.length > 50 ? item.name.slice(0, 50) + "..." : item.name,
              });
            }
            return acc;
          }, []);

          setOptions(uniqueData);
        },
      });
    } else {
      setOptions([]);
    }
  };

  const handleCheckboxChange = (type: string) => {
    setFormData((prevData: any) => ({
      ...prevData,
      type: type,
    }));
  };

  function formatHourToTimeString(hour: number | string) {
    if (hour === "") {
      return "";
    }

    const formattedHour = Math.min(Math.max(Number(hour), 0), 23);
    const formattedTimeString = formattedHour.toString().padStart(2, "0") + ":00";
    return formattedTimeString;
  }

  const handleTimeInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;

    // Ensure that the input is a valid number or an empty string
    const newValue = value === "" ? "" : parseInt(value, 10);

    setFormData((prevData: any) => ({
      ...prevData,
      startTime: newValue,
    }));
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      className="fixed h-screen flex justify-center items-center z-50 w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 max-h-full"
      style={{
        overlay: {
          backgroundColor: "rgba(0, 0, 0, 0.5)",
        },
      }}
    >
      <div id="authentication-modal">
        <div className="relative w-full max-w-md max-h-full" style={{ width: "450px" }}>
          <div className="relative rounded-lg shadow dark:bg-gray-700">
            <button
              type="button"
              className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-black"
              onClick={closeModal}
            >
              <Close />
              <span className="sr-only">Close modal</span>
            </button>
            <div className="px-6 py-6 lg:px-8 bg-white outline outline-2 rounded-lg">
              <div className="flex justify-between mb-4">
                <button
                  className={`text-lg font-medium w-1/2 flex items-center justify-center focus:outline-none px-4 py-2 ${
                    selectedOption === "Class"
                      ? "text-black border-b-2 border-primary"
                      : "text-gray-500 hover:text-black"
                  }`}
                  onClick={() => setSelectedOption("Class")}
                >
                  Predmet
                </button>
                <div className="border-l border-gray-300 mx-4 h-8" /> {/* Divider */}
                <button
                  className={`text-lg font-medium w-1/2 flex items-center justify-center focus:outline-none px-4 py-2 ${
                    selectedOption === "Custom"
                      ? "text-black border-b-2 border-primary"
                      : "text-gray-500 hover:text-black"
                  }`}
                  onClick={() => setSelectedOption("Custom")}
                >
                  Vlastné
                </button>
              </div>
              {selectedOption === "Class" ? (
                <>
                  {/* Predmet */}
                  <form className="space-y-6" onSubmit={handleFormSubmit}>
                    <div>
                      <Combobox
                        value={selectedCourse}
                        onChange={(value) => {
                          setSelectedCourse(value);
                          setFormData((prevData: any) => ({
                            ...prevData,
                            subject: value?.name,
                          }));
                        }}
                      >
                        <ComboboxInput
                          displayValue={(course: CourseGUI) => course?.name}
                          placeholder="*Názov predmetu"
                          onChange={handleInputChangeComboBox}
                          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-black"
                        />
                        <ComboboxOptions
                          anchor="bottom"
                          className="absolute z-50 bg-white border block rounded-lg border-gray-300 text-gray-900 text-sm"
                        >
                          {options.map((course) => (
                            <ComboboxOption key={uuid()} value={course}>
                              {course.name}
                            </ComboboxOption>
                          ))}
                        </ComboboxOptions>
                      </Combobox>
                    </div>

                    <div>
                      <input
                        type="text"
                        name="professor"
                        id="professor"
                        value={formData.professor}
                        onChange={handleInputChange}
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-black"
                        placeholder="*Vyučujúci"
                        required
                      />
                    </div>

                    <div>
                      <input
                        type="text"
                        name="room"
                        id="roomName"
                        value={formData.room}
                        onChange={handleInputChange}
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-black"
                        placeholder="*Miestnosť"
                        required
                      />
                    </div>

                    <div>
                      <select
                        name="day"
                        id="day"
                        value={formData.day || ""}
                        onChange={handleInputChange}
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-black"
                        required
                      >
                        {formData.day === undefined && (
                          <option value="" disabled hidden>
                            *Deň
                          </option>
                        )}
                        <option value={1}>Pondelok</option>
                        <option value={2}>Utorok</option>
                        <option value={3}>Streda</option>
                        <option value={4}>Štvrtok</option>
                        <option value={5}>Piatok</option>
                      </select>
                    </div>

                    <div className="flex space-x-4">
                      <div className="w-1/2">
                        <input
                          type="time"
                          name="startTime"
                          id="startTime"
                          value={formData.startTime ? formatHourToTimeString(formData.startTime) : ""}
                          onChange={handleTimeInputChange}
                          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-black"
                          placeholder="*00:00"
                          required
                        />
                      </div>

                      <div className="w-1/2">
                        <input
                          type="number"
                          name="duration"
                          id="duration"
                          value={formData.duration}
                          onChange={handleInputChange}
                          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-black"
                          placeholder="*Dĺžka trvania (h)"
                          required
                        />
                      </div>
                    </div>
                    <div className="flex space-x-9">
                      <label className="checkbox-label">
                        <input
                          type="checkbox"
                          name="type"
                          id="typeLecture"
                          checked={formData.type === "lecture"}
                          onChange={() => handleCheckboxChange("lecture")}
                          className="hidden"
                        />
                        <span className="checkbox"></span>
                        Prednáška
                      </label>

                      <label className="checkbox-label">
                        <input
                          type="checkbox"
                          name="type"
                          id="typeLaboratory"
                          checked={formData.type === "laboratory"}
                          onChange={() => handleCheckboxChange("laboratory")}
                          className="hidden"
                        />
                        <span className="checkbox"></span>
                        Laboratórium
                      </label>

                      <label className="checkbox-label">
                        <input
                          type="checkbox"
                          name="type"
                          id="typeExercise"
                          checked={formData.type === "exercise"}
                          onChange={() => handleCheckboxChange("exercise")}
                          className="hidden"
                        />
                        <span className="checkbox"></span>
                        Cvičenie
                      </label>
                    </div>

                    <div className="relative flex items-center">
                      <div
                        className="w-6 h-6 rounded-full border border-gray-300 mr-2"
                        style={{ backgroundColor: formData.color || "#fff" }}
                      />
                      <select
                        name="color"
                        id="color"
                        value={formData.color || ""}
                        onChange={handleInputChange}
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-black"
                        required
                      >
                        {formData.day === undefined && (
                          <option value="" disabled hidden>
                            *Farba
                          </option>
                        )}
                        <option value="#ff9900">Pomarančová</option>
                        <option value="#d9534f">Paradajková</option>
                        <option value="#5cb85c">Limetková</option>
                        <option value="#5ebed7">Nebíčková</option>
                        <option value="#428bca">Tmavo-modrá</option>
                        <option value="#9b59b6">Purpurová</option>
                        <option value="#f0ad4e">Banánová</option>
                        <option value="#a94442">Hnedá</option>
                      </select>
                    </div>

                    <button
                      type="submit"
                      className="w-full text-black bg-primary hover:bg-blue-400 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                    >
                      Uložiť
                    </button>
                  </form>
                </>
              ) : (
                <>
                  {/* Vlastne */}
                  <form className="space-y-6" onSubmit={handleFormSubmit}>
                    <div>
                      <input
                        type="text"
                        name="subject"
                        id="courseName"
                        value={formData.subject}
                        onChange={handleInputChange}
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-black"
                        placeholder="*Názov predmetu"
                        required
                      />
                    </div>

                    <div>
                      <input
                        type="text"
                        name="room"
                        id="roomName"
                        value={formData.room}
                        onChange={handleInputChange}
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-black"
                        placeholder="*Miestnosť"
                        required
                      />
                    </div>

                    <div>
                      <select
                        name="day"
                        id="day"
                        value={formData.day || ""}
                        onChange={handleInputChange}
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-black"
                        required
                      >
                        {formData.day === undefined && (
                          <option value="" disabled hidden>
                            *Deň
                          </option>
                        )}
                        <option value={1}>Pondelok</option>
                        <option value={2}>Utorok</option>
                        <option value={3}>Streda</option>
                        <option value={4}>Štvrtok</option>
                        <option value={5}>Piatok</option>
                      </select>
                    </div>

                    <div className="flex space-x-4">
                      <div className="w-1/2">
                        <input
                          type="time"
                          name="startTime"
                          id="startTime"
                          value={formData.startTime ? formatHourToTimeString(formData.startTime) : ""}
                          onChange={handleTimeInputChange}
                          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-black"
                          placeholder="*00:00"
                          required
                        />
                      </div>

                      <div className="w-1/2">
                        <input
                          type="number"
                          name="duration"
                          id="duration"
                          value={formData.duration}
                          onChange={handleInputChange}
                          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-black"
                          placeholder="*Dĺžka trvania (h)"
                          required
                        />
                      </div>
                    </div>

                    <div>
                      <textarea
                        name="description"
                        id="description"
                        value={formData.description || ""}
                        onChange={(e) => setFormData({ ...formData, description: e.target.value })}
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-black"
                        placeholder="Popis udalosti"
                        style={{ height: "89.5px" }}
                        rows={4}
                      />
                    </div>

                    <div className="relative flex items-center">
                      <div
                        className="w-6 h-6 rounded-full border border-gray-300 mr-2"
                        style={{ backgroundColor: formData.color || "#fff" }}
                      />
                      <select
                        name="color"
                        id="color"
                        value={formData.color || ""}
                        onChange={handleInputChange}
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-black"
                        required
                      >
                        {formData.day === undefined && (
                          <option value="" disabled hidden>
                            *Farba
                          </option>
                        )}
                        <option value="#ff9900">Pomarančová</option>
                        <option value="#d9534f">Paradajková</option>
                        <option value="#5cb85c">Limetková</option>
                        <option value="#5ebed7">Nebíčková</option>
                        <option value="#428bca">Tmavo-modrá</option>
                        <option value="#9b59b6">Purpurová</option>
                        <option value="#f0ad4e">Banánová</option>
                        <option value="#a94442">Hnedá</option>
                      </select>
                    </div>

                    <button
                      type="submit"
                      className="w-full text-black bg-primary hover:bg-blue-400 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                    >
                      Uložiť
                    </button>
                  </form>
                </>
              )}
              <form className="space-y-6" onSubmit={handleFormSubmit}></form>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default BlockForm;
