import React, { useState } from "react";
import Modal from "react-modal";
import { LoginModalType } from "../../types/types";
import Close from "../Icons/Close";
import { useUpdatePersonalNumber } from "../../hooks/queries/useLoginQuery";
import { Typography } from "@mui/material";

const LoginModal: React.FC<LoginModalType> = ({ isOpen, onRequestClose = () => { } }) => {
  const [errorMessage, setErrorMessage] = useState<boolean>(false);
  const [errorMessageText, setErrorMessageText] = useState<string>("");
  const [personalNumber, setPersonalNumber] = useState<string>("");
  const { mutate } = useUpdatePersonalNumber();
  const [wrongPersonalNumber, setWrongPersonalNumber] = useState<boolean>(true);

  const handleClick = () => {
    if (personalNumber !== "" && !wrongPersonalNumber) {
      mutate(personalNumber, {
        onError: () => {
          setErrorMessage(true);
          setErrorMessageText("Používateľ nebol nájdený");
        },
        onSuccess: () => {
          setErrorMessage(false);
          onRequestClose();
        },
      });
    } else {
      setErrorMessage(true);
      setErrorMessageText("Používateľ nebol nájdený");
      console.log("Wrong personal number!");
    }
  };

  function afterOpenModal() {
    console.log("Modal open");
  }

  const handleNumericInput = (e: React.FormEvent<HTMLInputElement>) => {
    const input = e.currentTarget;
    input.value = input.value.replace(/[^0-9]/g, "");
  };

  const onChangeHandler = (e: React.FormEvent<HTMLInputElement>) => {
    setPersonalNumber(e.currentTarget.value);

    const validFormat = (e.currentTarget.value.length === 5 || e.currentTarget.value.length === 6);

    if (!validFormat) {
      setErrorMessage(true);
      setErrorMessageText("Musí obsahovať 5 alebo 6 číslic");
      setWrongPersonalNumber(true);
    } else {
      setErrorMessage(false);
      setWrongPersonalNumber(false);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onAfterOpen={afterOpenModal}
      onRequestClose={onRequestClose}
      className="fixed h-screen flex justify-center items-center z-50 w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 max-h-full"
    >
      <div id="authentication-modal" className="w-[360px] max-h-full">
        <div className="relative w-full max-h-full">
          <div className="relative rounded-lg shadow dark:bg-gray-700">
            <button
              type="button"
              className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-black"
              onClick={onRequestClose}
            >
              <Close />
              <span className="sr-only">Close modal</span>
            </button>
            <div className="px-6 py-6 lg:px-8 bg-white outline outline-2 rounded-lg">
              <h3 className="mb-4 text-xl font-medium text-gray-900 dark:text-black">Zadajte osobné číslo</h3>
              <form className="space-y-6" action="#">
                <div>
                  <input
                    name="personalNumber"
                    id="personalNumber"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-black"
                    placeholder="562213"
                    required
                    onInput={handleNumericInput}
                    onChange={onChangeHandler}
                  />
                </div>
                {errorMessage && <Typography sx={{ color: "red" }}>{errorMessageText}</Typography>}
                <button
                  type="button"
                  className={`w-full text-white bg-primary hover:bg-blue-400 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                  ${errorMessage || wrongPersonalNumber ? "cursor-not-allowed" : ""}`}
                  disabled={errorMessage || wrongPersonalNumber}
                  onClick={handleClick}
                >
                  Potvrdiť
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default LoginModal;
