import React, { useState } from "react";
import { Drawer, Box, Tabs, Tab, TextField, Switch, Typography, IconButton } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";

interface SidebarProps {
  isOpen: boolean;
  toggleSidebar: () => void;
}

const Sidebar: React.FC<SidebarProps> = ({ isOpen, toggleSidebar }) => {
  const [activeTab, setActiveTab] = useState(0); 
  const [samplePredmet, setSamplePredmet] = useState(true);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSamplePredmet(event.target.checked);
  };

  return (
    <Drawer anchor="left" open={isOpen} onClose={toggleSidebar}>
      <Box sx={{ width: 300, padding: 2 }}>
        <Tabs
          value={activeTab}
          onChange={handleTabChange}
          textColor="inherit"
          variant="fullWidth"
          sx={{
            mb: 2,
            "& .MuiTab-root": {
              color: "#5ebed7",
              backgroundColor: "white",
            },
            "& .Mui-selected": {
              color: "white",
              backgroundColor: "#5ebed7",
            },
            "& .MuiTabs-indicator": {
              backgroundColor: "#5ebed7",
            },
          }}
        >
          <Tab label="Predmety" />
          <Tab label="Výmeny" />
        </Tabs>

        <Box>
          <Box display="flex" alignItems="center" mb={2}>
            <TextField
              label="Hľadať Predmet"
              variant="outlined"
              size="small"
              fullWidth
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#5ebed7",
                  },
                },
                "& .MuiInputLabel-root": {
                  color: "#5ebed7",
                },
                "& .MuiInputBase-input": {
                  color: "black", 
                },
              }}
            />
            <IconButton
              sx={{
                ml: 1,
                backgroundColor: "#5ebed7", 
                color: "white", 
                "&:hover": {
                  backgroundColor: "#4aa8c3", 
                },
              }}
            >
              <AddIcon />
            </IconButton>
          </Box>

          <Box display="flex" alignItems="center">
            <Switch
              checked={samplePredmet}
              onChange={handleSwitchChange}
              sx={{
                "& .MuiSwitch-thumb": {
                  backgroundColor: "#5ebed7", 
                },
                "& .Mui-checked": {
                  color: "#5ebed7",
                },
              }}
            />
            <Typography variant="body1" ml={2}>
              Sample Predmet
            </Typography>
              <IconButton
                sx={{ ml: 1, color: "#5ebed7" }}
              >
                <CloseIcon />
              </IconButton>
          </Box>
        </Box>
      </Box>
    </Drawer>
  );
};

export default Sidebar;
