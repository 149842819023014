import axios from "../../utils/axios";
import { CourseGUI } from "../../types/types";
import { useMutation } from "@tanstack/react-query";

export const useGetAllCourses = () => {
  return useMutation({
    mutationFn: async (substring: string): Promise<CourseGUI[]> => {
      const { data } = await axios.get(`Timetable/GetCoursesByNameSubstring/${substring}`);
      return data;
    },
  });
};
