import axios from "../../utils/axios";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Teacher, TeacherRest, TimetableEvent, TimetableEventRest } from "../../types/types";

export const useAddEvent = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ timetableEvent }: { timetableEvent: TimetableEventRest }) => {
      return axios.post("Timetable/AddEvent", timetableEvent, {
        headers: {
          "Content-Type": "application/json",
        },
      });
    },
    onSuccess: async () => {
      queryClient.invalidateQueries({ queryKey: ["currentUsersTimetable"] });
    },
  });
};

export const useEditEvent = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ formData, timetableEvent }: { formData: TimetableEvent; timetableEvent: TimetableEventRest }) => {
      return axios.put(
        `Timetable/UpdateEvent/${formData.course.name}/${formData.startTime}/${formData.duration}/${formData.day}`,
        timetableEvent,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
    },
    onSuccess: async () => {
      queryClient.invalidateQueries({ queryKey: ["currentUsersTimetable"] });
    },
  });
};

export const useDeleteEvent = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ formData }: { formData: TimetableEvent }) => {
      return axios.delete(
        `Timetable/DeleteEvent/${formData.course.name}/${formData.startTime}/${formData.duration}/${formData.day}`
      );
    },
    onSuccess: async () => {
      queryClient.invalidateQueries({ queryKey: ["currentUsersTimetable"] });
    },
  });
};

export const mapFormDataToTimetableEvent = (formData: TimetableEvent): TimetableEventRest => {
  const teachers: TeacherRest[] = formData.teachers.map((teacher: Teacher) => ({
    fullName: teacher.fullName,
    roles: [
      {
        roleType: 2,
      },
    ],
    email: "",
  }));

  let typeAsNumber: number = 0;
  switch (formData.type) {
    case "lecture":
      typeAsNumber = 1;
      break;
    case "laboratory":
      typeAsNumber = 2;
      break;
    case "exercise":
      typeAsNumber = 3;
      break;
    case "custom":
      typeAsNumber = 4;
      break;
    default:
      typeAsNumber = 5;
      break;
  }
  const timetableEvent: TimetableEventRest = {
    type: typeAsNumber,
    day: formData.day || 0,
    startTime: formData.startTime || 0,
    duration: formData.duration || 0,
    course: {
      code: "",
      shortcut: "",
      name: formData.course.name,
      type: 1,
    },
    room: {
      name: formData.room.name,
      type: 1,
    },
    teachers: teachers,
  };
  return timetableEvent;
};

export const useGetDownloadTimeTable = () => {
  return useMutation({
    mutationFn: async (): Promise<void> => {
      const response = await axios.get("Timetable/ExportTimetableToExcel", {
        responseType: "blob",
      });

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "UserTimetable.xlsx");

      document.body.appendChild(link);
      link.click();

      if (link.parentNode) {
        link.parentNode.removeChild(link);
      }
      window.URL.revokeObjectURL(url);
    },
  });
};
